ol.progtrckr {
    list-style-type: none;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    counter-reset: step;
    margin-bottom: 130px !important;
}

ol.progtrckr li {
    /*line-height: 3.8rem;*/
    cursor: pointer;
    white-space: break-spaces;
    /*text-overflow: ellipsis;*/
    flex: 1 1;
    max-width: 130px;

}

ol.progtrckr li span {
    font-size-adjust: inherit;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    bottom: -175%;
    right: 49%;
}

@media (max-width: 1000px) {
    .progtrckr li span {
        display: none;
    }
}

@media (max-width: 640px) {
    ol.progtrckr li {
        border-bottom: none !important;
    }
}

.progtrckr em {
    display: none;
}

ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 10px dotted silver;
}

ol.progtrckr li.progtrckr-doing {
    color: #0079BF;
    border-bottom: 10px dotted #0079BF;
}

ol.progtrckr li.progtrckr-done {
    color: #00334A;
    border-bottom: 10px dotted #00334A;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
}

ol.progtrckr li:before {
    position: relative;
    bottom: -25px;
    float: left;
}

ol.progtrckr li.progtrckr-todo:before {
    content: counter(step);
    counter-increment: step;
    color: white;
    background-color: silver;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
    align-content: center;
}

ol.progtrckr li.progtrckr-todo:hover:before {
    color: #0079BF;
}

ol.progtrckr li.progtrckr-doing:before {
    content: counter(step);
    counter-increment: step;
    color: white;
    background-color: #0079BF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
    align-content: center;
}

ol.progtrckr li.progtrckr-doing:hover:before {
    color: #0079BF;
}

ol.progtrckr li.progtrckr-done:before {
    content: counter(step);
    counter-increment: step;
    color: white;
    background-color: #00283B;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
    align-content: center;
}

ol.progtrckr li.progtrckr-done:hover:before {
    color: #00283B;
}

.progtrckr {
    margin-bottom: 50px;
}
